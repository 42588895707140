<template>
    <nav>
        <ol class="m-0 flex list-none flex-wrap items-center gap-4 p-0">
            <template
                v-for="(breadcrumbRoute, i) in breadcrumbRoutes"
                :key="breadcrumbRoute"
            >
                <li
                    class="font-semibold text-gray-600"
                    :class="i > 0 ? 'hidden md:block' : ''"
                >
                    {{ breadcrumbRoute }}
                </li>
                <li
                    v-if="i !== breadcrumbRoutes.length - 1"
                    class="hidden font-semibold text-gray-600 md:block"
                >
                    /
                </li>
            </template>
        </ol>
    </nav>
</template>

<script setup lang="ts">
import { useRoute } from '#app'
import { computed } from 'vue'

const route = useRoute()
const breadcrumbRoutes = computed(() => {
    if (route.meta.breadcrumb) {
        return route.meta.breadcrumb
    }

    return route.fullPath
        .split('/')
        .filter(item => item !== '')
        .filter(item => isNaN(Number(item)))
        .map(item => item.charAt(0).toUpperCase() + item.slice(1))
})
</script>
