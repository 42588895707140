<template>
    <div
        class="layout-sidebar overflow-auto"
        @mouseenter="onMouseEnter"
        @mouseleave="onMouseLeave"
    >
        <div class="flex items-center justify-center px-6 pt-10 sm:pb-8">
            <div class="flex flex-col items-center gap-6">
                <nuxt-link
                    :to="
                        viewer !== undefined
                            ? facilityHomeLink(viewer.homeFacility.slug)
                            : orgHomeLink()
                    "
                    class="app-logo"
                >
                    <img
                        :src="
                            activeFacility.branding.logo ??
                            activeFacility.organization.logo
                        "
                        :alt="viewer?.homeFacility.shortName"
                        class="max-h-[10rem]"
                    />
                </nuxt-link>

                <nuxt-link
                    v-if="viewer !== undefined && !isKiosk"
                    :to="facilityHomeLink(viewer.homeFacility.slug)"
                    class="ml-4 flex items-center gap-2 whitespace-nowrap rounded px-6 py-3 text-center font-semibold text-gray-600 hover:bg-gray-200"
                >
                    <i class="pi pi-home" />
                    {{ viewer.homeFacility.shortName }}
                </nuxt-link>
            </div>

            <button
                class="layout-sidebar-anchor p-link z-2 mb-2"
                type="button"
                @click="anchor"
            />
        </div>

        <div class="mb-12 flex-1 pb-8">
            <app-menu />
        </div>

        <div class="mb-12 text-center md:hidden">
            <prime-button
                type="button"
                label="Sign Out"
                icon="pi pi-sign-out"
                text
                severity="secondary"
                :loading="isSigningOut"
                @click="signOut"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import AppMenu from './AppMenu.vue'
import { useLayout } from './composables/layout'
import { useSignOut, useViewer } from '~/composables/use-login'
import { useRouteLink } from '~/composables/routing'
import NuxtLink from '#app/components/nuxt-link'
import PrimeButton from 'primevue/button'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useLayoutMode } from '~/composables/use-layout-mode'

const { isKiosk } = useLayoutMode()
const { layoutState } = useLayout()
const viewer = useViewer()
const { orgHomeLink, facilityHomeLink } = useRouteLink()

let timeout = null

const onMouseEnter = () => {
    if (!layoutState.anchored.value) {
        if (timeout) {
            clearTimeout(timeout)
            timeout = null
        }
        layoutState.sidebarActive.value = true
    }
}

const onMouseLeave = () => {
    if (!layoutState.anchored.value) {
        if (!timeout) {
            timeout = setTimeout(
                () => (layoutState.sidebarActive.value = false),
                300
            )
        }
    }
}

const anchor = () => {
    layoutState.anchored.value = !layoutState.anchored.value
}

const activeFacility = useActiveFacility()
const { isSigningOut, signOut } = useSignOut()
</script>
