<template>
    <header class="flex items-center justify-between">
        <div class="flex items-center gap-2 sm:gap-4 md:gap-8">
            <prime-button
                type="button"
                class="js-TopBarMenuButton inline-flex !h-10 !w-10 shrink-0 items-center justify-center !rounded-full transition-colors duration-200 ease-in hover:bg-primary-600"
                link
                @click="onMenuToggle"
            >
                <i
                    class="pi pi-bars text-xl text-gray-500 transition-colors duration-200 hover:text-white"
                />
            </prime-button>

            <app-breadcrumb />
        </div>

        <div>
            <ul class="flex list-none items-center md:gap-3">
                <li>
                    <nuxt-link :to="profileLink()">
                        <prime-button
                            :label="selectedCustomer.firstName"
                            text
                            severity="secondary"
                            :icon="
                                selectedCustomer.id === viewer?.customerId
                                    ? 'pi pi-user'
                                    : 'pi pi-users'
                            "
                        />
                    </nuxt-link>
                </li>

                <li class="-mr-2 sm:mr-0 lg:hidden">
                    <nuxt-link :to="profileSettingsLink()">
                        <prime-button
                            text
                            severity="secondary"
                            icon="pi pi-cog"
                        />
                    </nuxt-link>
                </li>

                <li class="hidden lg:block">
                    <prime-button
                        label="Profile Settings"
                        text
                        severity="secondary"
                        icon="pi pi-cog"
                        @click="handleClickProfileSettings"
                    />
                </li>

                <li class="hidden md:block">
                    <prime-button
                        type="button"
                        label="Sign Out"
                        icon="pi pi-sign-out"
                        text
                        severity="secondary"
                        :loading="isSigningOut"
                        @click="signOut"
                    />
                </li>
            </ul>
        </div>
    </header>
</template>

<script setup lang="ts">
import AppBreadcrumb from './AppBreadcrumb.vue'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useLayout } from './composables/layout'
import PrimeButton from 'primevue/button'
import { useSelectedCustomer } from '~/components/Layouts/ProfileLayout/composables/use-selected-customer'
import { useEditProfileModal } from '~/components/Modals/EditProfile/use-edit-profile-modal'
import { useSignOut, useViewer } from '~/composables/use-login'
import { useRouteLink } from '~/composables/routing'
import NuxtLink from '#app/components/nuxt-link'

const { profileLink, profileSettingsLink } = useRouteLink()
const { onMenuToggle } = useLayout()

const outsideClickListener = ref(null)
const topbarMenuActive = ref(false)

onMounted(() => {
    bindOutsideClickListener()
})

onBeforeUnmount(() => {
    unbindOutsideClickListener()
})

const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = event => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false
            }
        }
        document.addEventListener('click', outsideClickListener.value)
    }
}
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener)
        outsideClickListener.value = null
    }
}
const isOutsideClicked = event => {
    if (!topbarMenuActive.value) return

    const sidebarEl = document.querySelector('.layout-topbar-menu')
    const topbarEl = document.querySelector('.layout-topbar-menu-button')

    return !(
        sidebarEl.isSameNode(event.target) ||
        sidebarEl.contains(event.target) ||
        topbarEl.isSameNode(event.target) ||
        topbarEl.contains(event.target)
    )
}

const viewer = useViewer()
const selectedCustomer = useSelectedCustomer()

const showEditProfileModal = useEditProfileModal()
function handleClickProfileSettings() {
    showEditProfileModal()
}

const { isSigningOut, signOut } = useSignOut()
</script>
