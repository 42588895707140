import { computed, reactive, toRefs, inject, provide } from 'vue'

const LAYOUT_CONFIG_KEY = 'LAYOUT_CONFIG_KEY'
const LAYOUT_STATE_KEY = 'LAYOUT_STATE_KEY'
const mobileBreakpoint = 768

export function createLayout() {
    const layoutConfig = reactive({
        ripple: false,
        inputStyle: 'outlined',
        theme: 'indigo',
        scale: 14
    })

    provide(LAYOUT_CONFIG_KEY, layoutConfig)

    const layoutState = reactive({
        staticMenuDesktopInactive: false,
        overlayMenuActive: false,
        sidebarActive: false,
        anchored: false,
        overlaySubmenuActive: false,
        staticMenuMobileActive: false,
        menuHoverActive: false,
        activeMenuItem: null
    })

    provide(LAYOUT_STATE_KEY, layoutState)
}

export function useLayout() {
    const layoutConfig = inject(LAYOUT_CONFIG_KEY)
    const layoutState = inject(LAYOUT_STATE_KEY)

    const setScale = scale => {
        layoutConfig.scale = scale
    }

    const setActiveMenuItem = item => {
        layoutState.activeMenuItem = item.value || item
    }

    const onMenuToggle = () => {
        if (window.innerWidth > mobileBreakpoint) {
            layoutState.staticMenuDesktopInactive =
                !layoutState.staticMenuDesktopInactive
        } else {
            layoutState.staticMenuMobileActive =
                !layoutState.staticMenuMobileActive
        }
    }

    const isSidebarActive = computed(
        () =>
            layoutState.overlayMenuActive ||
            layoutState.staticMenuMobileActive ||
            layoutState.overlaySubmenuActive
    )

    const isDesktop = computed(() => window.innerWidth > mobileBreakpoint)

    const isSlim = computed(() => false)
    const isSlimPlus = computed(() => false)

    const isHorizontal = computed(() => false)

    return {
        layoutConfig: toRefs(layoutConfig),
        layoutState: toRefs(layoutState),
        setScale,
        onMenuToggle,
        isSidebarActive,
        setActiveMenuItem,
        isSlim,
        isSlimPlus,
        isHorizontal,
        isDesktop
    }
}
