<template>
    <ul>
        <template
            v-for="(item, i) in model"
            :key="item"
        >
            <app-menu-item
                :item="item"
                root
                :index="i"
            />

            <li class="menu-separator"></li>
        </template>
    </ul>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRouteLink } from '~/composables/routing'
import { useTicketsEnabled } from '~/composables/use-ticket'
import { useRpQuery } from '~/composables/graphql'
import { useQuery } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import AppMenuItem from '~/components/Layouts/ProfileLayout/AppMenuItem.vue'
import { useRouter } from 'vue-router'
import {
    useSelectedCustomer,
    useSetSelectedCustomer
} from '~/components/Layouts/ProfileLayout/composables/use-selected-customer'
import { useSignOut } from '~/composables/use-login'
import { useActiveFacility } from '~/composables/use-active-facility'

const router = useRouter()
const setSelectedCustomer = useSetSelectedCustomer()
const selectedCustomer = useSelectedCustomer()
const isTicketsEnabled = useTicketsEnabled()
const activeFacility = useActiveFacility()
const { signOut } = useSignOut()
const {
    facilityHomeLink,
    profileBookingsLink,
    profileCustomerInfoLink,
    profileInvoicesLink,
    profileLink,
    profileMembershipsLink,
    profilePassesLink,
    profileContactUsLink,
    profilePaymentMethodsLink
} = useRouteLink()
const model = computed(() => {
    const accountItems: {
        label: string
        icon: string
        to?: string
        command?: () => void
    }[] = [
        {
            label: 'Summary',
            icon: 'pi pi-fw pi-home',
            to: profileLink()
        },
        {
            label: 'Order History',
            icon: 'pi pi-fw pi-shopping-bag',
            to: profileInvoicesLink()
        },
        {
            label: 'Memberships',
            icon: 'pi pi-fw pi-tag',
            to: profileMembershipsLink()
        },
        {
            label: 'Bookings',
            icon: 'pi pi-fw pi-calendar',
            to: profileBookingsLink()
        },
        {
            label: 'Passes',
            icon: 'pi pi-fw pi-ticket',
            to: profilePassesLink()
        },
        {
            label: 'Payment Methods',
            icon: 'pi pi-fw pi-credit-card',
            to: profilePaymentMethodsLink()
        },
        // {
        //     label: 'Agreements',
        //     icon: 'pi pi-fw pi-file',
        //     to: profileAgreementsLink()
        // },
        {
            label: 'Customer Info',
            icon: 'pi pi-fw pi-info-circle',
            to: profileCustomerInfoLink()
        }
    ]

    if (isTicketsEnabled.value) {
        accountItems.push({
            label: 'Contact Us',
            icon: 'pi pi-fw pi-envelope',
            to: profileContactUsLink()
        })
    }

    accountItems.push({
        label: 'Sign Out',
        icon: 'pi pi-fw pi-sign-out',
        command: () => {
            signOut()
        }
    })

    const items = [
        {
            label: selectedCustomer.value.commaName,
            items: accountItems
        }
    ]

    const viewer = viewerData.value?.viewer
    if (
        viewer !== undefined &&
        viewer !== null &&
        viewer.customers.edges.length > 0
    ) {
        items.push({
            label: 'My Account',
            items: [
                {
                    label: 'Me',
                    icon: 'pi pi-fw pi-user',
                    command: () => {
                        setSelectedCustomer({
                            id: viewer.primaryCustomer.id,
                            commaName: viewer.primaryCustomer.commaName,
                            firstName: viewer.primaryCustomer.firstName
                        })
                        router.push(profileLink())
                    }
                },
                ...viewer.customers.edges.map(edge => ({
                    label: edge.node.commaName,
                    icon: 'pi pi-fw pi-users',
                    command: () => {
                        setSelectedCustomer({
                            id: edge.node.id,
                            commaName: edge.node.commaName,
                            firstName: edge.node.firstName
                        })
                        router.push(profileLink())
                    }
                }))
            ]
        })
    }

    return items
})

// TODO: Paging?
const query = useRpQuery()
const { data: viewerData } = useQuery({
    queryKey: ['ViewerCustomersQuery'],
    placeholderData: previousData => previousData,
    queryFn: () =>
        query(
            graphql(/* GraphQL */ `
                query ViewerCustomersQuery {
                    viewer {
                        primaryCustomer {
                            id
                            commaName
                            firstName
                        }
                        customers(first: 30) {
                            edges {
                                node {
                                    id
                                    commaName
                                    firstName
                                }
                            }
                        }
                    }
                }
            `)
        )
})
</script>
