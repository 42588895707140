import { useDialog } from 'primevue/usedialog'
import EditProfile from '~/components/Settings/EditProfile.vue'

export function useEditProfileModal(): () => Promise<void> {
    const dialog = useDialog()

    return () => {
        return new Promise(resolve => {
            dialog.open(EditProfile, {
                props: {
                    header: 'Profile Settings',
                    style: {
                        width: '40rem'
                    },
                    modal: true,
                    pt: {
                        header: {
                            class: 'text-xl font-semibold'
                        }
                    },
                    ptOptions: {
                        mergeProps: true
                    }
                },
                onClose: () => {
                    resolve()
                }
            })
        })
    }
}
